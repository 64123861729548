import "./../../styles/typography.css";

import { graphql, useStaticQuery } from "gatsby";

import { Helmet } from "react-helmet";
import React from "react";

const SEO = ({ description, lang = "de", meta = [], keywords = [], title }) => {
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
        title
        description
      }
    }
  `);

  const metaDescription = description || (data.site && data.site.description) || "";
  const siteTitle = (data.site && data.site.title) || "";
  const siteAuthor = (data.site && data.site.author && data.site.author.name) || "";

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title === siteTitle ? "%s" : `%s | ${siteTitle}`}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: siteAuthor,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
                name: "keywords",
                content: keywords.join(", "),
              }
            : []
        )
        .concat(meta)}
    >
      {/* <link rel="preconnect" href="https://use.typekit.net" crossorigin />
      <link
        rel="stylesheet"
        media="print"
        onload="this.onload=null;this.removeAttribute('media');"
        href="https://use.typekit.net/dlm8hgf.css"
      ></link>
      <noscript>
        {`<link
          rel="stylesheet"
          href="https://use.typekit.net/dlm8hgf.css"
        />`}
      </noscript> */}
    </Helmet>
  );
};

export default SEO;
