import { Box, Img, chakra } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

const Map = ({ lat, lng, zoom }) => {
  const isBrowser = typeof window !== "undefined";

  const mapContainer = useRef();

  const [mapContainerSize, setMapContainerSize] = useState({ width: 0, height: 0 });
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (isBrowser) {
      const width = mapContainer.current.offsetWidth;
      const height = mapContainer.current.offsetHeight;

      setMapContainerSize({ width: width, height: height });

      const aspectRatio = height / width;

      if (width > height) {
        setMapSize({ width: 640, height: Math.trunc(640 * aspectRatio) });
      } else {
        setMapSize({ width: Math.trunc(640 / aspectRatio), height: 640 });
      }
    }
  }, []);

  return (
    <Box ref={mapContainer} w="full" h="full">
      <Img
        htmlWidth={mapContainerSize.width}
        htmlHeight={mapContainerSize.height}
        width={mapContainerSize.width + "px"}
        height={mapContainerSize.height + "px"}
        loading="lazy"
        alt="Standort Karte"
        src={`https://maps.googleapis.com/maps/api/staticmap?autoscale=2&style=feature:poi|visibility:off&zoom=${zoom}&size=${mapSize.width}x${mapSize.height}&maptype=roadmap&key=AIzaSyCFJfECxU5jvyxiNpSP60jUoZrTiyilSng&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${lat},${lng}`}
      />
    </Box>
  );
};

export default Map;
