import "./timeline.css";

import React from "react";

const Timeline = ({ history }) => {
  return (
    <div className="timeline-container">
      {history.map((data, idx) => (
        <TimelineItem data={data} key={idx} />
      ))}
    </div>
  );
};

export default Timeline;

const TimelineItem = ({ data }) => (
  <div className="timeline-item">
    <div className="timeline-item-content">
      <time>{data.time}</time>
      {data.title && <h2>{data.title}</h2>}
      {data.description &&
        data.description.split("\n").map((block, index) => <p key={index}>{block}</p>)}
      <span className="circle" />
    </div>
  </div>
);
